import * as R from 'ramda';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useState, useEffect, useCallback } from 'react';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { AbsoluteBox } from '../../ui';
// feature select-mui
import { StyledFormControl } from './ui';
//////////////////////////////////////////////////

const SelectMui = (props: Object) => {
  const {
    options,
    wrapperStyles,
    multiple = true,
    initialValue = [],
    maxMenuHeight = 190,
    handleChangeCallback,
    muiFieldSize = 'small',
    label = G.getWindowLocale('titles:choose', 'Choose'),
  } = props;

  const [value, setValue] = useState(initialValue);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = useCallback((event: Object) => {
    const value = G.getEventTargetValue(event);

    setValue(value);
  }, []);

  useEffect(() => {
    G.callFunctionWithArgs(handleChangeCallback, value);
  }, [value]);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: maxMenuHeight,
      },
    },
  };

  return (
    <StyledFormControl
      {...G.spreadUiStyles(wrapperStyles)}
      size={muiFieldSize}
      focusedOrHasValue={R.or(isFocused, G.isNotNilAndNotEmpty(value))}
    >
      <InputLabel id='select-mui' size={muiFieldSize} sx={{ top: '-1px' }}>
        {label}
      </InputLabel>
      <Select
        value={value}
        id='select-mui'
        multiple={multiple}
        size={muiFieldSize}
        labelId='select-mui'
        onBlur={handleBlur}
        onFocus={handleFocus}
        MenuProps={menuProps}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        endAdornment={
          G.isNotNilAndNotEmpty(value) &&
          <AbsoluteBox
            right='0px'
            cursor='pointer'
            padding='10px 5px'
            onClick={() => setValue([])}
          >
            {I.closeIcon(G.getTheme('colors.dark.blue'))}
          </AbsoluteBox>
        }
      >
        {
          options.map(({ label, value }: Object, index: number) => (
            <MenuItem value={value} key={`${index}${label}`}>
              {label}
            </MenuItem>
          ))
        }
      </Select>
    </StyledFormControl>
  );
};

export {
  SelectMui,
};
