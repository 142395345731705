import React, { memo, useState, useCallback } from 'react';
import { Chip, TextField, Autocomplete } from '@mui/material';
// helpers/constants
import * as G from '../../helpers';
// feature select-mui
import { StyledFormControl } from './ui';
//////////////////////////////////////////////////

const AutocompleteMui = memo((props: Object) => {
  const {
    options,
    multiple = true,
    initialValue = [],
    wrapperStyles = {},
    maxMenuHeight = 190,
    handleChangeCallback,
    muiFieldSize = 'small',
    label = G.getWindowLocale('titles:choose', 'Choose'),
  } = props;

  const [value, setValue] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = useCallback((event: Event, newValue: Object) => {
    setValue(newValue);

    handleChangeCallback(newValue);
  }, []);

  const inputBaseStyles = isFocused ?
  {
    '& .MuiTextField-root .MuiInputBase-root': {
      position: 'absolute',
      height: 'fit-content',
      backgroundColor: 'white',
    },
  } : {
    '& .MuiTextField-root .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiAutocomplete-endAdornment': {
      marginTop: '1px',
    },
  };

  const placeholder = G.ifElse(
    G.isNilOrEmpty(value),
    label,
    G.ifElse(isFocused, G.getWindowLocale('titles:search', 'Search'), ''),
  );

  return (
    <StyledFormControl
      {...G.spreadUiStyles(wrapperStyles)}
      size={muiFieldSize}
      focusedOrHasValue={isFocused}
    >
      <Autocomplete
        limitTags={1}
        id='select-mui'
        options={options}
        size={muiFieldSize}
        multiple={multiple}
        onBlur={handleBlur}
        sx={inputBaseStyles}
        onFocus={handleFocus}
        onChange={handleChange}
        defaultValue={initialValue}
        getOptionLabel={(option: Object) => option.label}
        ListboxProps={{ style: { maxHeight: maxMenuHeight } }}
        renderTags={(tagValue: Array, getTagProps: Function) =>
          tagValue.map((option: Object, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });

            const style = isFocused ? { marginTop: '-1px' } : {
              margin: 0,
              pointerEvents: 'none',
              '& .MuiChip-deleteIcon': {
                pointerEvents: 'all',
              },
            };

            return (
              <Chip
                {...tagProps}
                key={key}
                sx={style}
                clickable={false}
                size={muiFieldSize}
                label={option.label}
              />
            );
          })
        }
        renderInput={(params: Object) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            sx={{ '& .MuiInputBase-input': { pointerEvents: 'none' }}}
          />
        )}
      />
    </StyledFormControl>
  );
});

export default AutocompleteMui;

